<template>
  <ListContainerWidget
    id="members-list"
    :app-id="app.id"
    :view-more-location="getLocation('members')"
    :disable-view-more="disableViewMore"
    :size="addOnSize"
    :stretch="false"
  >
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->

      <!-- Panel actions -->
      <ViewAllButton item-type="members" />    
    </template>

    <MembersList
      v-if="canList || isStaff"
      :per-page="perPage"
      enable-card-size="xl"
      :members="members"
      :is-loading="isLoading"
      :total="total || 0"
      class="members-table"
      @total-updated="setAddOnSize($event)"
      @handleLoadOfNewItems="handleChanger(null, $event)"
    />
  </ListContainerWidget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import OrderBy from "@core/constants/OrderBy";
import { checkPermissions } from "@/@core/utils/roles-utils";

import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'

// import MembersList from "@/views/apps/member/components/MembersList.vue";
// import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";

export default {
  name: "MembersListWidget",

  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    ListContainerWidget,
    MembersList: () => import("@/views/apps/member/components/MembersList.vue" /* webpackChunkName: "MembersList" */),
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    // WidgetActions: () => import("@/@core/components/widget-actions/WidgetActions.vue" /* webpackChunkName: "WidgetActions" */),
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    perPage: {
      type: Number,
      default: 8,
    },
    disableViewMore: Boolean,
    organizationKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      page: 1,
      isLoading: false,
      index: 0,
      timer: null,
      searchString: "",
      finalTypeSelected: null,
      currentPage: 1,
    };
  },
  computed: {
    canList() {
      return (
        checkPermissions(
          "index",
          "member",
          this.loggedMemberRoles,
          this.collective
        ) || this.isStaff
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    types() {
      return this.$store.getters.types?.member?.unpaginated;
    },
    members() {
      if (
        this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ]
      ) {
        return this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ].unpaginated;
      }
      return [];
    },
    total() {
      if (
        this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ]
      ) {
        return this.$store.getters.communityMembers[
          this.$store.getters.currentCollective.slug
        ].meta.total;
      }
      return 0;
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("1 ++++++++++++ --> WATCH!!! MembersListWidget.vue (targetIsVisible): ", newValue)
      if (newValue && !this.members.length) {
        // console.log("2 --> WATCH!!! MembersListWidget.vue (targetIsVisible): ", newValue)
        this.loadAllData()
      }
    }
  },
  
  async created() {
    //
  },

  methods: {
    async loadAllData() {
      // console.log('MembersListWidget (loadAllData)')
      this.isLoading = true;
      await this.fetchData({
        page: this.currentPage,
        searchString: "",
        forceAPICall: true,
      });
      // await this.fetchTypes();
      this.isLoading = false;
    },

    async fetchTypes() {
      await this.$store.dispatch("getItems", {
        itemType: "types",
        storedKey: "member",
        forceAPICall: true,
        requestConfig: {
          modelType: "member",
          onlyUsedTypes: 1,
        },
      });
      /*await this.$store.dispatch('getItems', {
        itemType: 'communityMembersByType',
        forceAPICall: true,
        requestConfig: {
          onlyUsedTypes: 1
        },
      });*/
    },
    async fetchData({ page, searchString, forceAPICall = false }) {
      this.isLoading = true;
      if (searchString === "") {
        await this.$store.dispatch("getItems", {
          itemType: "communityMembers",
          storedKey: this.organizationKey
            ? this.organizationKey
            : this.$store.getters.currentCollective.slug,
          page,
          forceAPICall,
          perPage: this.perPage,
          requestConfig: {
            orderByName: OrderBy.ASC,
            typeKey: this.finalTypeSelected,
          },
        });
      } else {
        await this.$store.dispatch("getItems", {
          itemType: "communityMembers",
          storedKey: this.$store.getters.currentCollective.slug,
          page,
          forceAPICall,
          perPage: this.perPage,
          requestConfig: {
            orderByName: OrderBy.ASC,
            searchString,
            typeKey: this.finalTypeSelected,
          },
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async handleLoadOfNewItems({ page }) {
      // console.log('MembersListWidget handleLoadOfNewItems({ page }, targetIsVisible): ', page, this.targetIsVisible)
      if (!this.isLoading && this.members.length <= this.total && this.targetIsVisible) {
        await this.fetchData({
          page,
          searchString: this.searchString,
          forceAPICall: true,
        });
      }
    },
    handleChanger(search, page = { page: 1 }) {
      this.isSearching = search?.length > 0;
      this.currentPage = page.page;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchData({
          page: this.currentPage,
          searchString: search,
          searchCountry: this.selectedCountry ?? [],
          forceAPICall: true,
        });
      }, 800);
    },
    searchItems(value) {
      this.searchString = value;
      if (value == "") {
        return this.fetchData({
          page: 1,
          searchString: "",
          forceAPICall: true,
        });
      }
      const searchTerm = value.toLowerCase().trim();
      this.fetchData({ page: 1, searchString: searchTerm, forceAPICall: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.members-table {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
  padding-bottom: 20px;
}
</style>
